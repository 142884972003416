import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { get } from 'lodash';
import favicon from './images/favicon.png';
import logolarge from './images/logolarge.png';
import settingmanager from './images/1_product-manager.svg';
class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      if (menuState === 'sellerOrderMenuOpen') {
        this.setState({ [menuState]: true, orderManagerOpen: true });
      }
      else if (menuState === 'sellerMenuOpen' || menuState === 'buyerMenuOpen') {
        this.setState({ [menuState]: true, userManagerMenuOpen: true });
      }
      else if (['miscellaneousMenuOpen', 'accountManagerMenuOpen', 'gstManagerMenuOpen', 'productManagerMenuOpen', 'sellerRewardMenuOpen', 'buddyRewardMenuOpen', 'supportManagerMenuOpen'].includes(menuState)) {
        this.setState({ [menuState]: true, viewMoreMenuOpen: true });
      }
      else {
        this.setState({ [menuState]: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/customer-order-manager', state: 'orderManagerOpen' },
      { path: '/OrderSearch', state: 'orderManagerOpen' },
      { path: '/reward-orders-coupon', state: 'orderManagerOpen' },
      { path: '/reward-orders-product', state: 'orderManagerOpen' },
      { path: '/tables', state: 'tablesMenuOpen' },
      { path: '/users', state: 'userManagerMenuOpen' },
      { path: '/agents', state: 'userManagerMenuOpen' },
      { path: '/delete-accounts', state: 'userManagerMenuOpen' },
      { path: '/search-users', state: 'userManagerMenuOpen' },
      { path: '/vendor-requests', state: 'requestManagerMenuOpen' },
      { path: '/influencer-requests', state: 'requestManagerMenuOpen' },
      { path: '/products', state: 'productManagerMenuOpen' },
      { path: '/category-manager', state: 'productManagerMenuOpen' },
      { path: '/reward-manager', state: 'productManagerMenuOpen' },
      { path: '/withdrawal-manager', state: 'accountManagerMenuOpen' },
      { path: '/reward-seller', state: 'sellerRewardMenuOpen' },
      { path: '/buddy-reward', state: 'buddyRewardMenuOpen' },
      { path: '/buddy-Withdrawal', state: 'buddyRewardMenuOpen' },
      { path: '/Withdrawal-seller', state: 'sellerRewardMenuOpen' },
      { path: '/call-enquiry', state: 'supportManagerMenuOpen' },
      { path: '/error-pages', state: 'errorPagesMenuOpen' },
      { path: '/vendor-wallet', state: 'walletManagerMenuOpen' },
      { path: '/offer-wallet', state: 'walletManagerMenuOpen' },
      { path: '/video-call-orders', state: 'accountManagerMenuOpen' },
      { path: '/NAC-kikopayment', state: 'accountManagerMenuOpen' },
      { path: '/delivery-manager', state: 'deliveryManagerMenuOpen' },
      { path: '/delivery-data', state: 'deliveryManagerMenuOpen' },
      { path: '/selers-orders', state: 'gstManagerMenuOpen' },
      { path: '/buyer-orders', state: 'gstManagerMenuOpen' },
      { path: '/ondc-gst-manager', state: 'gstManagerMenuOpen' },
      { path: '/taskbucks-buyers', state: 'miscellaneousMenuOpen' },
      { path: '/microwebsite-order-manager', state: 'microwebsiteOrderManagerMenuOpen' },
      { path: '/microwebsite-settlement', state: 'microwebsiteOrderManagerMenuOpen' },
      { path: '/pickMyWork-users', state: 'miscellaneousMenuOpen' },
      { path: '/invoices', state: 'miscellaneousMenuOpen' },
      { path: '/live-user', state: 'miscellaneousMenuOpen' },
      { path: '/events', state: 'miscellaneousMenuOpen' },
      { path: '/flag', state: 'miscellaneousMenuOpen' },
      { path: '/seller', state: 'sellerMenuOpen' },
      { path: '/vendors', state: 'sellerMenuOpen' },
      { path: '/ondc-seller', state: 'sellerMenuOpen' },
      { path: '/customer-list-seller', state: 'sellerMenuOpen' },
      { path: '/buyers-address', state: 'sellerMenuOpen' },
      { path: '/orders', state: 'sellerOrderMenuOpen' },
      { path: '/longdistance', state: 'sellerOrderMenuOpen' },
      { path: '/customer-manager', state: 'buyerMenuOpen' },
      { path: '/influencers', state: 'buyerMenuOpen' },
      { path: '/view-more', state: 'viewMoreMenuOpen' },
      { path: '/videos', state: 'viewMoreMenuOpen' },
      { path: '/invoice-sellers', state: 'viewMoreMenuOpen' },
      { path: '/master-catalogues', state: 'viewMoreMenuOpen' },
      { path: '/coin-manager', state: 'viewMoreMenuOpen' },
      { path: '/url-generator', state: 'viewMoreMenuOpen' },
      { path: '/ondc-order-manager', state: 'ondcManagerMenuOpen' },
      { path: '/ondc-pending-order', state: 'ondcManagerMenuOpen' },
      { path: '/ondc-settlement', state: 'ondcManagerMenuOpen' },
      { path: '/rsp-dashboard', state: 'ondcManagerMenuOpen' },
      { path: '/igm-dashboard', state: 'ondcManagerMenuOpen' },
      { path: '/ondc-offers', state: 'ondcManagerMenuOpen' },
      { path: '/ondc-return', state: 'ondcManagerMenuOpen' },
      { path: '/catalogues', state: 'catalogManagerMenuOpen' },
      { path: '/price-catalogues', state: 'catalogManagerMenuOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  render() {
    let authData = JSON.parse(localStorage.getItem('auth'))
    const loginUserRole = get(authData, 'role', '');
    const loginUserName = get(authData, 'data.userName', '')
    return (
      <nav className='sidebar sidebar-offcanvas float-left sidebar_fix' id='sidebar'>
        <div className='text-center sidebar-brand-wrapper sidebar-fixed fixed-logo kiko-navbar'>
          {/* <img alt="" src={favicon} /> */}
          <img alt="" src={logolarge} width={"230px"} height={"60px"} className="kiko-img" />
        </div>
        <ul className='nav'>
        <li
              className={
                this.isPathActive('/settings') ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className='nav-link' to='/contact'>
                <img alt="" className='sidebar_icon' src={settingmanager} />
                <span className='menu-title'>Contact Manager</span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive('/blog') ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className='nav-link' to='/blog'>
                <img alt="" className='sidebar_icon' src={settingmanager} />
                <span className='menu-title'>Blog Manager</span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive('/user') ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className='nav-link' to='/user'>
                <img alt="" className='sidebar_icon' src={settingmanager} />
                <span className='menu-title'>User Manager</span>
              </Link>
            </li>
            <li
              className={
                this.isPathActive('/resume') ? 'nav-item active' : 'nav-item'
              }
            >
              <Link className='nav-link' to='/resume'>
                <img alt="" className='sidebar_icon' src={settingmanager} />
                <span className='menu-title'>Resume Manager</span>
              </Link>
            </li>
        </ul>

      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  async componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }
}

export default withRouter(Sidebar);
