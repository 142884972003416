import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import { isEmpty } from 'lodash';

const Login = lazy(() => import('./user-pages/Login'));
const settingManager = lazy(() => import('./settingManager/index'));
const contactManager = lazy(() => import('./SellerReward/contactManager'));
const s3Upload = lazy(() => import('./SellerReward/s3Upload'));
const BlockManager = lazy(() => import('./BlogManager/BlockManager'));
const CreateBlog = lazy(() => import('./BlogManager/CreateBlog'));
const CreateUser = lazy(() => import('./UserManager/CreateUser'));
const CreateResume = lazy(() => import('./ResumeManager/CreateResume'));
const BlogPost = lazy(() => import('./SellerReward/BlogPost'));
const UserManager = lazy(() => import('./UserManager/UserManager'));
const ResumeManager = lazy(() => import('./ResumeManager/ResumeManager'));

class AppRoutes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: !isEmpty(JSON.parse(localStorage.getItem('auth'))),
    };
  }

  render() {
    const { isLoggedIn } = this.state;
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
        <Route exact path='/settings' component={settingManager}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/contact' component={contactManager}>
            {!isLoggedIn && <Redirect to='/admin/login' />}
          </Route>
          <Route exact path='/s3' component={s3Upload}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/blog' component={BlockManager}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/blog-review/:id' component={CreateBlog}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/user-review/:id' component={CreateUser}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/resume-review/:id' component={CreateResume}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/post' component={BlogPost}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/user' component={UserManager}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/resume' component={ResumeManager}>
            {!isLoggedIn && <Redirect to='/login' />}
          </Route>
          <Route exact path='/login' component={Login} />
          {/* <Redirect to='/login' /> */}
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
